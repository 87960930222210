//
// Sortable tables with list.js
//

.table  {
    [data-sort] {
        cursor: pointer;
    }
    
    .thead-dark {
        [data-sort] {
            &::after {
                content: str-replace(url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='#{$table-dark-head-color}'/></svg>"), "#", "%23");
                margin-left: .25rem;
            }
        }
    }

    .thead-light {
        [data-sort] {
            &::after {
                content: str-replace(url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='#{$table-head-color}'/></svg>"), "#", "%23");
                margin-left: .25rem;
            }
        }
    }
}
