//
// Lavalamp
//


/*!
 * Lavalamp
 * http://lavalamp.magicmediamuse.com/
 */

.lavalamp {
	position: relative;
}
.lavalamp-item {
	z-index: 5;
	position: relative;
}
.lavalamp-object {
	position: absolute;
	top: 0;
	left: 0;
}

/* Custom easing transitions */

.lavalamp .lavalamp-object {
	transition-property:transform, width, height;
}
.lavalamp .lavalamp-object.ease {
	transition-timing-function: ease;
}
.lavalamp .lavalamp-object.ease-in {
	transition-timing-function: ease-in;
}
.lavalamp .lavalamp-object.ease-out {
	transition-timing-function: ease-out;
}
.lavalamp .lavalamp-object.ease-in-out {
	transition-timing-function: ease-in-out;
}
.lavalamp .lavalamp-object.linear {
	transition-timing-function: linear;
}
.lavalamp .lavalamp-object.easeInQuad {
	transition-timing-function: cubic-bezier(0.550, 0.085, 0.680, 0.530);
}
.lavalamp .lavalamp-object.easeInCubic {
	transition-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
}
.lavalamp .lavalamp-object.easeInQuart {
	transition-timing-function: cubic-bezier(0.895, 0.030, 0.685, 0.220);
}
.lavalamp .lavalamp-object.easeInQuint {
	transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
}
.lavalamp .lavalamp-object.easeInSine {
	transition-timing-function: cubic-bezier(0.470, 0.000, 0.745, 0.715);
}
.lavalamp .lavalamp-object.easeInExpo {
	transition-timing-function: cubic-bezier(0.950, 0.050, 0.795, 0.035);
}
.lavalamp .lavalamp-object.easeInCirc {
	transition-timing-function: cubic-bezier(0.600, 0.040, 0.980, 0.335);
}
.lavalamp .lavalamp-object.easeInBack {
	transition-timing-function: cubic-bezier(0.600, -0.280, 0.735, 0.045);
}
.lavalamp .lavalamp-object.easeOutQuad {
	transition-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
}
.lavalamp .lavalamp-object.easeOutCubic {
	transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
}
.lavalamp .lavalamp-object.easeOutQuart {
	-webkit-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
	-moz-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
	-o-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
	transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
}
.lavalamp .lavalamp-object.easeOutQuint {
	-webkit-transition-timing-function: cubic-bezier(0.230, 1.000, 0.320, 1.000);
	-moz-transition-timing-function: cubic-bezier(0.230, 1.000, 0.320, 1.000);
	-o-transition-timing-function: cubic-bezier(0.230, 1.000, 0.320, 1.000);
	transition-timing-function: cubic-bezier(0.230, 1.000, 0.320, 1.000);
}
.lavalamp .lavalamp-object.easeOutSine {
	-webkit-transition-timing-function: cubic-bezier(0.390, 0.575, 0.565, 1.000);
	-moz-transition-timing-function: cubic-bezier(0.390, 0.575, 0.565, 1.000);
	-o-transition-timing-function: cubic-bezier(0.390, 0.575, 0.565, 1.000);
	transition-timing-function: cubic-bezier(0.390, 0.575, 0.565, 1.000);
}
.lavalamp .lavalamp-object.easeOutExpo {
	-webkit-transition-timing-function: cubic-bezier(0.190, 1.000, 0.220, 1.000);
	-moz-transition-timing-function: cubic-bezier(0.190, 1.000, 0.220, 1.000);
	-o-transition-timing-function: cubic-bezier(0.190, 1.000, 0.220, 1.000);
	transition-timing-function: cubic-bezier(0.190, 1.000, 0.220, 1.000);
}
.lavalamp .lavalamp-object.easeOutCirc {
	-webkit-transition-timing-function: cubic-bezier(0.075, 0.820, 0.165, 1.000);
	-moz-transition-timing-function: cubic-bezier(0.075, 0.820, 0.165, 1.000);
	-o-transition-timing-function: cubic-bezier(0.075, 0.820, 0.165, 1.000);
	transition-timing-function: cubic-bezier(0.075, 0.820, 0.165, 1.000);
}
.lavalamp .lavalamp-object.easeOutBack {
	-webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
	-moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
	-o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
}
.lavalamp .lavalamp-object.easeInOutQuad {
	-webkit-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
	-moz-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
	-o-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
	transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
}
.lavalamp .lavalamp-object.easeInOutCubic {
	-webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
	-moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
	-o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
	transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
}
.lavalamp .lavalamp-object.easeInOutQuart {
	-webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
	-moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
	-o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
	transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
}
.lavalamp .lavalamp-object.easeInOutQuint {
	-webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
}
.lavalamp .lavalamp-object.easeInOutSine {
	-webkit-transition-timing-function: cubic-bezier(0.445, 0.050, 0.550, 0.950);
	-moz-transition-timing-function: cubic-bezier(0.445, 0.050, 0.550, 0.950);
	-o-transition-timing-function: cubic-bezier(0.445, 0.050, 0.550, 0.950);
	transition-timing-function: cubic-bezier(0.445, 0.050, 0.550, 0.950);
}
.lavalamp .lavalamp-object.easeInOutExpo {
	-webkit-transition-timing-function: cubic-bezier(1.000, 0.000, 0.000, 1.000);
	-moz-transition-timing-function: cubic-bezier(1.000, 0.000, 0.000, 1.000);
	-o-transition-timing-function: cubic-bezier(1.000, 0.000, 0.000, 1.000);
	transition-timing-function: cubic-bezier(1.000, 0.000, 0.000, 1.000);
}
.lavalamp .lavalamp-object.easeInOutCirc {
	-webkit-transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860);
	-moz-transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860);
	-o-transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860);
	transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860);
}
.lavalamp .lavalamp-object.easeInOutBack {
	-webkit-transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
	-moz-transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
	-o-transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
	transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
}
